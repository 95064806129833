import React, { memo } from "react";

import routes from "../src/views/customEntry/ZJBankInsuranceCustom/ZJBankInsuranceCustomContainer";
import { useRoutes } from "react-router-dom";
import {RecoilRoot} from "recoil";

const App = memo(() => {
	return <RecoilRoot><div style={{width:'100%',height:'100%'}}>{useRoutes(routes)}</div></RecoilRoot>;
});

export default App;
