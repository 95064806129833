import { Navigate } from "react-router-dom";
import { lazy } from "react";

const Home = lazy(() => import("./Home/index"));
const RechargePage = lazy(() => import("./recharge/index"));
const OrderDetail = lazy(() => import("./OrderDetail/index"));
const ResultContainer = lazy(() => import("./result/ResultContainer"));
const ShowPdfContainer = lazy(() => import("./showPdf/ShowPdfContainer"));
const routes = [
    {
        path: "/",
        element: <Navigate to="/home" />,
    },
    {
        path: "/home",
        element: <Home />,
    },
    {
        path: "/recharge",
        element: <RechargePage />,
    },{
        path: "/orderDetail",
        element: <OrderDetail />,
    },{
        path: "/result",
        element: <ResultContainer />,
    }, {
        path: "/showPdf",
        element: <ShowPdfContainer />,
    },
];

export default routes;
