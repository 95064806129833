import { BASE_URL, TIMEOUT } from "./config";

import axios from "axios";
import {RESPOND_CODE} from "@/common/commonUtils";
import {Toast} from "antd-mobile";

class Request {
	constructor(baseURL, timeout) {
		this.instance = axios.create({
			baseURL,
			timeout,
		});
		// TODO:请求拦截器

		// 响应拦截器
		this.instance.interceptors.response.use(
			(res) => {
				//  请求成功判断
				// if (res.data.code === 200) return res.data.data;
				// else {
				// 	Toast.show({
				// 		content:res.data.msg || '系统异常'
				// 	})
				// 	throw new Error(res.data.msg);
				// }
				return res.data
			},
			(err) => {
				//error todo
				Toast.show({
					content:'当前活动人数过多，请稍后再试~'
				})
				console.log("network error");
				throw new Error(err);
			}
		);
	}

	request(config) {
		return this.instance.request(config);
	}

	get(config) {
		return this.request({
			...config,
			method: "get",
		});
	}

	post(config) {
		return this.request({
			...config,
			method: "post",
		});
	}
}

const request = new Request(BASE_URL, TIMEOUT);

export default request;
